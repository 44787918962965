// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room {
  height: 100%;
  width: 100%;
  display: flex;
  min-width: 452px;
}
.room .roomElements {
  height: 100%;
  width: 200px;
}
.room .roomArea {
  height: calc(100% - 50px);
  overflow-y: auto;
}
.room .roomOptions {
  height: 50px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
