// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SnackbarItem-variantSuccess {
  background-color: #4caf50 !important;
  color: #fff !important;
}
.SnackbarItem-variantSuccess:hover {
  background-color: #357a38 !important;
  color: #fff !important;
}

.SnackbarItem-variantError {
  background-color: #f44336 !important;
  color: #fff !important;
}
.SnackbarItem-variantError:hover {
  background-color: #d2190b !important;
  color: #fff !important;
}

.SnackbarItem-variantInfo {
  background-color: #2196f3 !important;
  color: #fff !important;
}
.SnackbarItem-variantInfo:hover {
  background-color: #0a6ebd !important;
  color: #fff !important;
}

.SnackbarItem-variantWarning {
  background-color: #ff9800 !important;
  color: #fff !important;
}
.SnackbarItem-variantWarning:hover {
  background-color: #b36a00 !important;
  color: #fff !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
