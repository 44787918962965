import axios from 'axios';
import { urls } from '../config/Urls';
export const axiosExternal = axios;

/**
 * Gerenciador de pedidos
 */
class AxiosInstance {
  /**
   *
   */
  constructor() {
    this.baseUrl = urls.beServer;
    this.authorization = localStorage.getItem('token');
  }
  /**
   * Adiciona autorização ao pedido
   * @param {*} config
   * @return {Object}
   */
  _getHeaders() {
    let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!this.authorization) {
      this.authorization = localStorage.getItem('token');
    }
    config.headers = {
      Authorization: this.authorization,
      ...config.headers
    };
    return config;
  }
  /**
   *
   * @param {*} config
   * @return {Promise}
   */
  request() {
    let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return axios.request(this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} config
   * @return {Promise}
   */
  get(url) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return axios.get(this.baseUrl + url, this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} config
   * @return {Promise}
   */
  delete(url) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return axios.delete(this.baseUrl + url, this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} config
   * @return {Promise}
   */
  head(url) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return axios.head(this.baseUrl + url, this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} config
   * @return {Promise}
   */
  options(url) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return axios.options(this.baseUrl + url, this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} data
   * @param {*} config
   * @return {Promise}
   */
  post(url) {
    let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return axios.post(this.baseUrl + url, data, this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} data
   * @param {*} config
   * @return {Promise}
   */
  put(url) {
    let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return axios.put(this.baseUrl + url, data, this._getHeaders(config));
  }
  /**
   *
   * @param {*} url
   * @param {*} data
   * @param {*} config
   * @return {Promise}
   */
  patch(url) {
    let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return axios.patch(this.baseUrl + url, data, this._getHeaders(config));
  }
}
export default new AxiosInstance();