import { LOGIN, LOGOUT } from '../actions/login';
const initialState = {
  status: 'notRegistered'
};
export const loginLogout = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    ...initialState
  };
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        status: 'registered',
        ...action.payload
      };
    case LOGOUT:
      return {
        ...state,
        status: 'notRegistered'
      };
    default:
      return state;
  }
};