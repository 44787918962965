// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageSelectAndCrop {
  flex-grow: 1;
  width: 200px;
  height: 200px;
  position: relative;
}
.imageSelectAndCrop .cropArea {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
.imageSelectAndCrop .input {
  display: none;
}
.imageSelectAndCrop .center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.imageSelectAndCrop .box {
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  text-align-last: center;
}
.imageSelectAndCrop .img {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  border-radius: 4px;
}
.imageSelectAndCrop .posBottomRight {
  position: absolute;
  bottom: 8px;
  right: 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
