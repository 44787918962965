import io from 'socket.io-client';
import { urls } from '../config/Urls';
import alerts from './Alerts';
/**
 * Class for connecting to a websocket server
 * @class WebSocketClient
 * @todo quando o socket desconectar re-registrar todas as inscrições feitas pelos componentes.
 */
export class WebSocketClient {
  /**
   * Creates an instance of WebSocketClient.
   * @memberof WebSocketClient
   */
  constructor() {
    this._enabled = false;
    this._firstConnect = true;
    this.count = 0;
    this.chats = [];
    this._nick = false;
    const self = this;
    this.socket = io(urls.beSocket, {
      transports: ['websocket', 'polling'],
      // use WebSocket first, if available
      auth: {
        Authorization: localStorage.getItem('token')
      },
      reconnectionDelayMax: 10000
      // path: urls.version, //nao pode usar pois tem que configurar no .conf do apache
    });
    this.socket.on('connect', () => self.connect());
    this.socket.on('disconnect', () => self.disconnect());
    this.socket.on('error', () => alerts.error('Chat: Erro na conexão!'));
    this.socket.on('connect_error', error => {
      alerts.error('Chat: Erro na conexão! Reconectando...', {
        preventDuplicate: true
      });
    });
    this.socket.on('connect_timeout', () => {
      self.socket.io.opts.transports = ['polling', 'websocket'];
      alerts.warning('Chat: Navegador Antigo? Contate o administrador.');
    });
  }

  /**
   * Conecta no socket
   */
  connect() {
    const self = this;
    this._enabled = true;
    if (this._firstConnect) {
      this._firstConnect = false;
    } else {
      alerts.success('Chat: A conexão voltou!');
      this.chats.forEach(chat => {
        self.waitConnection().then(() => {
          this.socket.emit("join", {
            chat,
            nick: this._nick,
            noMessage: true
          });
        });
      });
    }
  }
  /**
   * Servidor desconectado do socket
   */
  disconnect() {
    this._enabled = false;
    alerts.error('Chat: Reconectando...');
  }

  /**
   * Aguarda conexão
   * @return {*}
   * @memberof WebSocketClient
   */
  waitConnection() {
    return new Promise((resolve, reject) => {
      if (this._enabled) {
        resolve();
      } else {
        const retry = 1000;
        let rejectCount = 10;
        const interval = setInterval(() => {
          if (this._enabled) {
            clearInterval(interval);
            resolve();
          }
          rejectCount--;
          if (rejectCount <= 0) {
            clearInterval(interval);
            alerts.error('Não foi possível conectar ao servidor');
            reject(new Error('Não foi possível conectar ao servidor'));
          }
        }, retry);
      }
    });
  }

  /**
   * Entra em um chat
   * @param {Number} chat
   * @param {String} nick
   * @param {Function} callBack
   */
  join(chat, nick, callBack) {
    // console.log('join', chat);

    this.waitConnection().then(() => {
      this._nick = nick;
      this.chats.push(chat);
      //adiciona na lista de chats observados
      this.socket.on("chatMessages".concat(chat), callBack);
      this.socket.emit("join", {
        chat,
        nick
      });
    }).catch(error => {
      callBack([{
        type: 'error',
        nick,
        msg: error.toString()
      }]);
    });
  }

  /**
   * Sai do chat
   * @param {Number} chat
   * @param {String} nick
   */
  leave(chat, nick) {
    this._nick = nick;

    // console.log('leave', chat);
    this.socket.emit("leave", {
      chat,
      nick
    });
    this.chats = this.chats.filter(c => {
      if (c !== chat) {
        return true;
      } else {
        this.socket.removeAllListeners("chatMessages".concat(c));
        return false;
      }
    });
  }

  /**
   * Envia uma mensagem para a sala
   * @param {Object} data: {chat: 38, event: 'chatMessage', other1: '', other2: ''}
   * @memberof WebSocketClient
   */
  send(data) {
    // console.log('send', data);
    this.socket.emit(data.event, data);
  }

  /**
   * Instance
   * @readonly
   * @static
   * @memberof WebSocketClient
   */
  static get inst() {
    if (WebSocketClient._instance == null) {
      WebSocketClient._instance = new WebSocketClient();
    }
    return WebSocketClient._instance;
  }
}