const version = '/v1';
let selectedUrls = {
  beServer: 'https://be.travelrpg.com' + version,
  beSocket: 'wss://be.travelrpg.com',
  version
};
if (window.location.hostname == 'localhost') {
  //dev
  selectedUrls = {
    ...selectedUrls,
    beServer: 'http://localhost:8080' + version,
    beSocket: 'ws://localhost:8080'
  };
}
if (window.location.hostname == 'fe-dev.travelrpg.com') {
  //dev
  selectedUrls = {
    ...selectedUrls,
    beServer: 'https://be-dev.travelrpg.com' + version,
    beSocket: 'wss://be-dev.travelrpg.com'
  };
}
export const urls = {
  ...selectedUrls
};