// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treeFolderWithOptions .settings {
  float: right;
}
.treeFolderWithOptions .settings:hover {
  color: #cb373a;
}
.treeFolderWithOptions .verticalAline {
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
}
.treeFolderWithOptions .renameIcon {
  margin-top: 7px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
