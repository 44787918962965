// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuBar {
  background-color: var(--backgroundGround);
  display: flex;
  width: 100%;
  height: 100%;
}
.menuBar .appBar {
  background-color: var(--backgroundGround);
  background-image: none;
  box-shadow: none;
  display: flex;
  flex-direction: row;
}
.menuBar .appBar .logo {
  width: 45px;
  height: 32px;
  margin: -8px 10px 0px 0px;
  border-radius: 8px;
}
.menuBar .nav {
  margin-top: 24px;
  width: 48px;
  display: flex;
  flex-direction: column;
}
.menuBar .nav .itemIcon {
  flex: 0;
  padding-left: 12px;
}
.menuBar .nav .itemImage {
  padding-left: 1px;
}
.menuBar .nav .favList {
  width: 48px;
  flex: 1;
}
.menuBar .content {
  background-color: var(--background);
  flex-grow: 1;
  border-radius: 4px;
  width: calc(100% - 48px);
  height: calc(100% - 24px);
  margin-top: 24px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
