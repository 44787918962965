// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardBox {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: var(--backgroundPaper);
}
.cardBox .messageViewer {
  flex-grow: 1;
  overflow-y: auto;
}
.cardBox .messageSend {
  flex-grow: 0;
}
.cardBox .message {
  word-break: break-all;
}
.cardBox .inputText {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
