// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageChat {
  background-color: transparent;
  /* Estilos para o modo escuro */
}
.messageChat:hover {
  background-color: rgba(203, 55, 58, 0.1);
}

.messageSystem {
  /* Estilos para o modo escuro */
}
.messageSystem:hover {
  background-color: rgba(203, 55, 58, 0.1);
}

.messageError {
  /* Estilos para o modo escuro */
}
.messageError:hover {
  background-color: rgba(203, 55, 58, 0.1);
}

.messageRoll {
  /* Estilos para o modo escuro */
}
.messageRoll:hover {
  background-color: rgba(203, 55, 58, 0.1);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
