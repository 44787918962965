// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatBox {
  flex-grow: 1;
  max-width: calc(100% - 200px);
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
}
.chatBox .messageViewer {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 100%;
}
.chatBox .messageSend {
  flex-grow: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 0px 10px 5px;
}
.chatBox .messageSend .messageInput {
  flex-grow: 1;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  margin-left: 5px;
  background-color: transparent;
  border: transparent;
  color: white;
  min-height: 40px;
  padding: 7px;
}
.chatBox .messageSend .messageButton {
  flex-grow: 0;
}
.chatBox .message {
  white-space: pre-line;
}
.chatBox .message .user {
  font-weight: bold;
}
.chatBox .roll {
  background-color: black;
  word-break: break-all;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
