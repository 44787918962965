// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.messageRoll .dicesRoll {
  display: inline-block;
  height: 24px;
  padding: 0 4px;
  background-color: var(--background);
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}
.messageRoll .dicesRoll.highlighted {
  background-color: var(--primary);
}
.messageRoll .dicesResults {
  background-color: var(--background);
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 0 8px;
}
.messageRoll .dicesResults.highlighted {
  border: 2px solid var(--primary);
}
.messageRoll .dicesResults .numberOne {
  color: #ff7373; /* Vermelho claro para números igual a 1 */
}
.messageRoll .dicesResults .numberMax {
  color: #7aff7a; /* Verde claro para números igual ao máximo do dado */
}
.messageRoll .dicesResults .numberFix {
  color: #bbb; /* Cinza claro para números fixos */
}
.messageRoll .dicesResults .diceFaces {
  display: none;
  margin-left: 5px;
  font-weight: normal;
  color: #888888;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
