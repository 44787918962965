/**
 * Classe que analiza mensagens do chat e constroi objeto enviado ao servidor
 * @export
 * @class MessageAnalyzer
 */
export class MessageAnalyzer {
  /**
   * Creates an instance of MessageAnalyzer.
   */
  constructor() {
    this.classes = [new RollModel(), new ClearModel(), new MessageModel()];
  }

  /**
   * Teste de mensagens genéricas
   * @param {String} messageStr
   * @param {Number} chat
   * @return {Object}
   */
  analyze(messageStr) {
    var _typeMessage, _typeMessage$createMe;
    let typeMessage = this.classes.find(el => {
      return el.test(messageStr) ? el : false;
    });
    if (!typeMessage) {
      typeMessage = this.classes[this.classes.length - 1];
    }
    return (_typeMessage = typeMessage) === null || _typeMessage === void 0 || (_typeMessage$createMe = _typeMessage.createMessage) === null || _typeMessage$createMe === void 0 ? void 0 : _typeMessage$createMe.call(_typeMessage, messageStr);
  }
}

/**
 * Mensagem Padrão e modelo de mensagem
 */
class MessageModel {
  /**
   * Creates an instance of MessageModel.
   */
  constructor() {
    //Verifica: nao é um comando, nao esta vazio, tem limite de 1000 caracteres
    this.regex = /^\s*(([^/]|[/]{2,})).{0,1001}$/;
  }

  /**
   * Testa texto de entrada para determinar se pertence a esse tipo de mensagem
   * @param {String} messageStr
   * @return {Boolean}
   */
  test(messageStr) {
    const find = this.regex.test(messageStr);
    return find;
  }

  /**
   * Cria objeto da mensagem para ser enviado ao servidor
   * @param {String} messageStr
   * @return {Object}
   */
  createMessage(messageStr) {
    return {
      event: 'chatMessage',
      msg: messageStr
    };
  }
}

/**
 * Mensagem Padrão e modelo de mensagem
 */
class ClearModel extends MessageModel {
  /**
   * Creates an instance of MessageModel.
   */
  constructor() {
    super();
    // testa se mensagem não esta vazia
    this.regex = /^\s*\/\s*(clearAll|clear|ca|c)((\s+.*)|$)/i;
  }

  /**
   * Cria objeto da mensagem para ser enviado ao servidor
   * @param {String} messageStr
   * @return {Object}
   */
  createMessage(messageStr) {
    return {
      event: /^\s*\/\s*(clearAll|ca).*$/i.test(messageStr) ? 'chatClearAll' : 'chatClear',
      msg: messageStr.replace(/^\s*\/\s*(clearAll|clear|ca|c)\s*/i, '')
    };
  }
}

/**
 * Classe de analise de chat e construção de objeto de mensagem
 */
class RollModel extends MessageModel {
  /**
   * Creates an instance of RollModel.
   */
  constructor() {
    super();
    this.regexStart = new RegExp(/^\s*\/\s*[roll|r]?\s+/); // "/roll ", "/r " ou "/ "

    this.regexExpression = new RegExp(/([/*+\-(\s]*[0-9]+\s*(((di|ad|d|r|b)\s*[0-9]+\s*)|f)?[)\s]*)+\s*/); // "10d10", "+15", "2f", "+2", "-(2)", "*2", "/2",
    this.regexResultEvaluation = new RegExp(/>([+-]?[0-9]+(>>|\.))?(>>|>|\.)?\s*/); // (">55>>", ">>>"), (">55.", ">."), (">>"), (">")

    this.regex = new RegExp(this.regexStart.source + this.regexExpression.source + '(' +
    // abre o sistema de avaliação de dificuldades
    this.regexResultEvaluation.source + this.regexExpression.source + ')?' +
    // fecha o sistema de avaliação de dificuldades
    '$',
    //final da string
    'm');
  }

  /**
   * Cria objeto da mensagem para ser enviado ao servidor
   * @param {String} messageStr
   * @return {Object}
   */
  createMessage(messageStr) {
    if ((messageStr.match(/\(/) || []).length !== (messageStr.match(/\)/) || []).length) {
      return {
        type: 'error',
        msgError: 'Erro: Parenteses não estão balanceados',
        msg: messageStr
      };
    }
    return {
      event: 'chatRoll',
      msg: messageStr,
      data: this.messageAnalyze(messageStr)
    };
  }

  /**
   * Analise
   * @param {*} messageStr
   * @return {*}
   */
  messageAnalyze(messageStr) {
    const rolls = [];
    //Ao escrever, cada linha nova é uma nova rolagem, só a primeira linha tem que ter o /r
    //rolagem, tem que ser sempre o ultimo pois suporta somente de /r a somente /
    messageStr.split('\n').forEach(roll => {
      // "/roll ", "/r " ou "/ "
      roll = roll.replace(this.regexStart, '');
      roll = this.makeObjectRoll(roll);
      rolls.push(roll);
    });
    return rolls;
  }

  /**
   * Cria objeto de rolagem aplicando as regras:
   * 1ª Somatória de dados/resultados:
   * - Aplica matemática ao resultado 2d6-1d6 => ({2,6})-({3}) = 5
   * - Realiza matemática a rolagem (2d6-1d6) => 1d6 ({3}) = 3
   *
   * 2ª Avaliação de resultados:
   * * [> diff] -> (D&D) --> /r 1d20 > 10
   * Verifica se ( res >= diff ) dando sucesso ou falha
   *
   * * [>> diff] -> (Storytelling, Vampiro Requiem) --> /r 8d10 >> 8
   * Conta cada dado e () se ( res >= diff - +1 sucesso )
   *
   * * [>(x). diff] (Storyteller, Vampiro A Máscara) --> /r 8d10 >. 6
   * Valor padrão de x = 1
   * Conta cada dado e () se ( res >= diff -> +1 sucesso ) ( res <= x -> -1 sucesso )
   *
   * * [>(x)>> diff] (GOT, M&M) --> /r 6d6+4 >>> 6
   * Valor padrão de x = 5
   * Verifica se ( res >= diff ).
   * Se ( res-x <= diff ) falha critica
   * Se ( res >= diff ) 1 Grau de sucesso
   * Se ( res+x >= diff ) 2 Graus de sucesso ...
   *
   * @param {string} rollEntry
   * @return {obj}
   */
  makeObjectRoll(rollEntry) {
    let roll = [{
      type: false,
      // 'expression', 'advantage','disadvantage', 'dice', 'reroll', 'fate', 'bonus'
      val: rollEntry.replaceAll(' ', '')
    }];
    roll = this.splitByRoll(roll); //Separa as expressões usando o [rollMod]
    roll = this.splitByDice(roll); //Separa os Objetos de rolagem [dice]
    roll = this.splitByBrace(roll); //Separa os Objetos de parenteses [brace]
    roll = this.splitByMathMod(roll); //Separa os [math] e [mod]
    roll = this.calculeBraces(roll); //Calcula os valores de lv e bc dos [brace]
    roll = this.warpMathsInBraces(roll); //Envolva os [math] em [brace]
    roll = this.calculeBraces(roll); //Calcula os valores de lv e bc dos [brace]
    roll = this.applyBraces(roll); //Aplica braces nos elementos [brace]

    //Simplificações
    roll = this.simplifyDices(roll); // Simplifica [dice] [dice] no nivel 0
    let unresolved = false;
    do {
      unresolved = false;
      if (this.simplifyValueValue(roll)) unresolved = true; // Resolve [value] [value]
      if (this.simplifyValueMathValue(roll)) unresolved = true; // Resolve [value] [math] [value]
      if (this.simplifyRemoveAloneInBraces(roll)) unresolved = true; // Rm el solitarios em braces
    } while (unresolved);
    return roll;
  }

  /**
   * Separa as expressões usando o [rollMod]
   * @param {Array} roll
   * @return {Array}
   */
  splitByRoll(roll) {
    const newRoll = [];
    roll.forEach(el => {
      if (el.type === false) {
        const el2 = el.val.match(this.regexResultEvaluation);
        //tem rollMod
        if ((el2 === null || el2 === void 0 ? void 0 : el2.length) > 0) {
          newRoll.push({
            type: false,
            val: el.val.substring(0, el2.index)
          });

          // />([0-9]+(>>|\.))?(>>|>|\.)?\s*/
          if (/>[+-]?[0-9]*>>/.test(el2[0])) {
            //rolagem de >(x)>> (GOT, M&M)
            const mod = parseInt(el2[0].replace(/>/, ''));
            newRoll.push({
              type: 'rollMod',
              eval: 'got',
              mod: isNaN(mod) ? 5 : mod
            });
          } else if (/>[+-]?[0-9]*\./.test(el2[0])) {
            //rolagem de >(x). (Storyteller, Vampiro A Máscara)
            const mod = parseInt(el2[0].replace(/[>|.]/, ''));
            newRoll.push({
              type: 'rollMod',
              eval: 'wod',
              mod: isNaN(mod) ? 1 : mod
            });
          } else if (/>>/.test(el2[0])) {
            //rolagem de >> (Storytelling, Vampiro Requiem)
            newRoll.push({
              type: 'rollMod',
              eval: 'nwod'
            });
          } else {
            //rolagem de > (D&D) />/.test(el2[0])
            newRoll.push({
              type: 'rollMod',
              eval: 'dnd'
            });
          }
          newRoll.push({
            type: false,
            val: el.val.substring(el2.index + el2[0].length, el.val.length)
          });
        } else {
          //nao tem rollMod
          newRoll.push(el);
        }
      } else {
        newRoll.push(el);
      }
    });
    return newRoll;
  }

  /**
   * Separa as expressões usando o [rollMod]
   * @param {Array} roll
   * @return {Array}
   */
  splitByDice(roll) {
    const newRoll = [];
    roll.forEach(el => {
      if (el.type === false) {
        const len = el.val.length;
        for (let i = 0; i < len; i++) {
          const filterString = el.val.substring(i, len);
          const el2 = filterString.match(/([+-]*[0-9]+(((di|ad|d|r|b)[0-9]+)|f))/);
          if ((el2 === null || el2 === void 0 ? void 0 : el2.length) > 0) {
            if (el2.index > 0) {
              //adiciona string ate o resultado encontrado de rolagem
              newRoll.push({
                type: false,
                val: filterString.substring(0, el2.index)
              });
            }
            const rollFinded = el2[0];
            const sig = rollFinded[0] != '-'; //true + false -
            const val = rollFinded.replace(sig ? '+' : '-', '');
            const diceType = /di/.test(val) ? 'disadvantage' : /ad/.test(val) ? 'advantage' : /r/.test(val) ? 'reroll' : /f/.test(val) ? 'fate' : /d/.test(val) ? 'dice' : /b/.test(val) ? 'bonus' : 'error';
            const splitWithType = val.split(/(di|ad|d|r|f|b)/);
            const qtd = parseInt(splitWithType[0]); // (x)d6
            const faces = diceType == 'fate' ? 3 : parseInt(splitWithType[splitWithType.length - 1]); // 2d(x)

            newRoll.push({
              type: 'dice',
              sig,
              qtd,
              diceType,
              faces,
              val
            });
            i += el2.index + rollFinded.length - 1;
          } else {
            //quando nao é um objeto de rolagem ou nao tem valores como )>30
            newRoll.push({
              type: false,
              val: filterString
            });
            i += filterString.length - 1;
          }
        }
      } else {
        newRoll.push(el);
      }
    });
    return newRoll;
  }

  /**
   * Separa os Objetos de parenteses [brace]
   * @param {Array} roll
   * @return {Array}
   * @memberof RollModel
   */
  splitByBrace(roll) {
    const newRoll = [];
    roll.forEach(el => {
      if (el.type === false && /(\(|\))/.test(el.val)) {
        const split = el.val.split(/([+-]*\(|\))/).filter(el => (el === null || el === void 0 ? void 0 : el.length) > 0);
        split.forEach(el2 => {
          if (/([+-]*\()/.test(el2)) {
            newRoll.push({
              type: 'brace',
              sig: el2[0] != '-',
              // (+1)=+ (-1)=- (1)=+
              val: true
            });
          } else if (/(\))/.test(el2)) {
            newRoll.push({
              type: 'brace',
              val: false
            });
          } else {
            newRoll.push({
              type: false,
              val: el2
            });
          }
        });
      } else {
        newRoll.push(el);
      }
    });
    return newRoll;
  }

  /**
   * Separa os Objetos de somas, subtrações, multiplicações e divisões em [math] e [mod]
   * @param {Array} roll
   * @return {Array}
   */
  splitByMathMod(roll) {
    const newRoll = [];
    roll.forEach(el => {
      if (el.type === false && /[/*+-]?[0-9]+/.test(el.val)) {
        const split = el.val.split(/([/*+-]?[0-9]+)/).filter(el => (el === null || el === void 0 ? void 0 : el.length) > 0);
        split.forEach(el2 => {
          //testa [math]
          if (/[/*][0-9]+/.test(el2)) {
            newRoll.push({
              type: 'math',
              sig: /\*[0-9]+/.test(el2) ? true : false
            });
            newRoll.push({
              type: 'value',
              sig: true,
              val: parseInt(el2.replace(/[/*+-]/, ''))
            });
            return;
          }

          //testa [mod]
          if (/[+-]?[0-9]+/.test(el2)) {
            newRoll.push({
              type: 'value',
              sig: /-[0-9]+/.test(el2) ? false : true,
              val: parseInt(el2.replace(/[+-]/, ''))
            });
            return;
          }

          //testa os [math] * e / sem numero
          if (/[\/*]/.test(el2)) {
            newRoll.push({
              type: 'math',
              sig: /\*/.test(el2) ? true : false
            });
            return;
          }

          //nao acho???
          console.warn(el2, 'Invalid');
          newRoll.push({
            type: false,
            val: el2
          });
        });
      } else if (el.type === false && (el.val == '*' || el.val == '/')) {
        newRoll.push({
          type: 'math',
          sig: /\*/.test(el.val) ? true : false
        });
      } else {
        newRoll.push(el);
      }
    });
    return newRoll;
  }

  /**
   * Define lv (level), bc (braceNumber), bcs ([braces]) de cada [brace] do array
   * @param {Array} oldRoll
   * @return {Array}
   */
  calculeBraces(oldRoll) {
    const roll = [];
    const braces = [];
    let braceCount = 0;
    oldRoll.forEach(el => {
      const elNew = {
        ...el
      };
      delete elNew.bcs;
      if (el.type === 'brace') {
        if (el.val) {
          braces.push(braceCount++); // incrementa o brace
          elNew.bcs = [...braces];
        } else {
          elNew.bcs = [...braces];
          braces.splice(-1, 1);
        }
      }
      roll.push(elNew);
    });
    return roll;
  }

  /**
   * Envolve os [math] em [brace]
   * @param {Array} roll
   * @return {Array}
   */
  warpMathsInBraces(roll) {
    let elWarped = 0;
    roll.forEach(el => {
      if (el.type === 'math') {
        el.wrap = false;
        elWarped++;
      }
    });
    const braceOpen = {
      type: 'brace',
      sig: true,
      val: true
    };
    const braceClose = {
      type: 'brace',
      val: false
    };
    for (let i = 0; i < elWarped; i++) {
      const posDiscovery = roll.findIndex(el => el.type == 'math' && el.wrap == false);
      roll[posDiscovery].wrap = true;
      //Encapsula com parenteses o elemento da frente
      if (roll[posDiscovery + 1].type === 'brace') {
        const endBrace = roll.findIndex(el => el.type == 'brace' && !el.val && el.bc == roll[posDiscovery + 1].bc //Numero da brace
        );
        roll.splice(endBrace + 1, 0, {
          ...braceClose
        }); //Insere depois o brace
      } else {
        roll.splice(posDiscovery + 2, 0, {
          ...braceClose
        }); //Insere depois o brace
      }
      if (roll[posDiscovery - 1].type === 'brace') {
        const startBrace = roll.findIndex(el => el.type == 'brace' && el.val && el.bc == roll[posDiscovery - 1].bc //Numero da brace
        );
        roll.splice(startBrace, 0, {
          ...braceOpen
        }); //Insere depois o brace
      } else {
        roll.splice(posDiscovery - 1, 0, {
          ...braceOpen
        }); //Insere antes o brace
      }
    }
    roll.forEach(el => {
      if (el.type === 'math') {
        delete el.wrap;
      }
    });
    return roll;
  }

  /**
   * Remover braces e aplicar níveis aos elementos
   * @param {Array} roll
   * @return {Array}
   */
  applyBraces(roll) {
    const newRoll = [];
    const braces = []; //braces
    let lastElement; //multiplicador

    roll.forEach((el, index) => {
      if (el.type === 'brace') {
        if (el.val) {
          //pega o brace aberto
          braces.push(el);
        } else {
          braces.splice(-1, 1); //remove o ultimo
        }
      } else {
        //sempre na base sem sinal
        if (el.type === 'rollMod') {
          newRoll.push({
            bcs: [],
            ...el
          });
        } else {
          var _lastElement, _braces$bcs, _braces;
          //faz a conta do sinal
          let sig = true;
          braces.forEach(b => sig = sig == b.sig);
          if (!sig && ((_lastElement = lastElement) === null || _lastElement === void 0 ? void 0 : _lastElement.type) === 'math') sig = true; //nao inverte se for 2º de uma * ou /
          sig = sig == el.sig; //calcula o sinal do elemento
          if (el.type === 'math') sig = el.sig; // nao se pode inverter sinal de math
          newRoll.push({
            ...el,
            bcs: (_braces$bcs = (_braces = braces[braces.length - 1]) === null || _braces === void 0 ? void 0 : _braces.bcs) !== null && _braces$bcs !== void 0 ? _braces$bcs : [],
            sig
          });
          lastElement = el;
        }
      }
    });
    return newRoll;
  }

  /**
   * Simplifica dados fora dos braces no estilo
   * @example
   * 1d6+1d6 => 2d6
   * 1d6-1d6 => 0
   * 1d6-2d6 => -1d6
   * (1d6+2d6) => (3d6)
   * (1d6-2d6) => (1d6-2d6)
   * @param {Array} roll
   * @return {Array}
   */
  simplifyDices(roll) {
    //Faz a simplificação dos dados fora de braces
    for (let i = 0; i < roll.length; i++) {
      const el = roll[i];
      //passa dado por dado somando todos os compatíveis a frente e removendo o elemento
      if (el.type === 'dice' && el.bcs.length === 0) {
        for (let j = i + 1; j < roll.length; j++) {
          const el2 = roll[j];
          if (el2.type === 'dice' && el2.bcs.length === 0 && el.diceType === el2.diceType &&
          //mesmo tipo de dado
          el.faces === el2.faces //mesmo numero de faces
          ) {
            let quantidade = el.sig ? el.qtd : -el.qtd;
            quantidade += el2.sig ? el2.qtd : -el2.qtd;
            roll[i].sig = quantidade >= 0;
            roll[i].qtd = Math.abs(quantidade);
            roll.splice(j, 1);
            j--;
          } else if (el2.type === 'rollMod') {
            break;
          }
        }
        //Remove dados com quantidade 0
        if (el.qtd === 0) {
          roll.splice(i, 1);
          i--;
        }
      }
    }
    return roll;
  }

  /**
   * Aplica somas e subtrações entre [mod]s
   * @param {Array} roll
   * @return {Boolean}
   * @memberof RollModel
   */
  simplifyValueValue(roll) {
    let applied = false;
    if (roll.length >= 2) {
      //Faz a simplificação de valores em mesmos níveis de braces
      for (let i = 0; i < roll.length; i++) {
        const el = roll[i];
        //passa dado por dado somando todos os compatíveis a frente e removendo o elemento
        if (el.type == 'value') {
          for (let j = i + 1; j < roll.length; j++) {
            const el2 = roll[j];
            if (roll[j - 1].type != 'math') {
              //nunca soma um elemento apos um math,
              //tb nao chega a somar um antes pois vai estar em niveis diferentes de braces
              if (el2.type == 'value' && el.bcs.at(-1) == el2.bcs.at(-1)) {
                const val = (el.sig ? el.val : -el.val) + (el2.sig ? el2.val : -el2.val);
                roll[i].val = Math.abs(val);
                roll[i].sig = val >= 0 ? true : false;
                roll.splice(j, 1);
                j--;
                applied = true;
              } else if (el2.type === 'rollMod') {
                break;
              }
            }
          }
          //Remove value com val 0
          if (el.val == 0) {
            roll.splice(i, 1);
            i--;
          }
        }
      }
    }
    return applied;
  }

  /**
   * Aplica [math] entre [mod]s
   * @param {Array} roll
   * @return {Boolean}
   * @memberof RollModel
   */
  simplifyValueMathValue(roll) {
    let applied = false;
    if (roll.length >= 3) {
      //avança elemento por elemento procurando uma conta.
      for (let i = 2; i < roll.length; i++) {
        const a = roll[i - 2];
        const b = roll[i - 1];
        const c = roll[i];
        if (a.type == 'value' && b.type == 'math' && c.type == 'value') {
          var _b$bcs, _a$bcs, _b$bcs2, _c$bcs;
          if ((b === null || b === void 0 || (_b$bcs = b.bcs) === null || _b$bcs === void 0 ? void 0 : _b$bcs.join(',')) == (a === null || a === void 0 || (_a$bcs = a.bcs) === null || _a$bcs === void 0 ? void 0 : _a$bcs.join(',')) && (b === null || b === void 0 || (_b$bcs2 = b.bcs) === null || _b$bcs2 === void 0 ? void 0 : _b$bcs2.join(',')) == (c === null || c === void 0 || (_c$bcs = c.bcs) === null || _c$bcs === void 0 ? void 0 : _c$bcs.join(','))) {
            const val1 = a !== null && a !== void 0 && a.sig ? a === null || a === void 0 ? void 0 : a.val : -(a === null || a === void 0 ? void 0 : a.val);
            const val2 = c !== null && c !== void 0 && c.sig ? c === null || c === void 0 ? void 0 : c.val : -(c === null || c === void 0 ? void 0 : c.val);
            const val = b !== null && b !== void 0 && b.sig ? val1 * val2 : val1 / (val2 != 0 ? val2 : 1); //evita divisão por 0
            roll[i - 2].val = Math.abs(val);
            roll[i - 2].sig = val >= 0 ? true : false;
            roll.splice(i - 1, 2);
            i--;
            applied = true;
          }
        }
      }
    }
    return applied;
  }

  /**
   * Remove elementos Solitários dentro dos Braces
   * @param {Array} roll
   * @return {Boolean}
   * @memberof RollModel
   */
  simplifyRemoveAloneInBraces(roll) {
    let applied = false;
    const countBraces = {};
    roll.forEach(el => {
      var _el$bcs;
      if ((el === null || el === void 0 || (_el$bcs = el.bcs) === null || _el$bcs === void 0 ? void 0 : _el$bcs.length) > 0) {
        el.bcs.forEach(bc => {
          if (countBraces[bc] == undefined) {
            countBraces[bc] = 0;
          }
          countBraces[bc]++;
        });
      }
    });
    for (const bc in countBraces) {
      if (Object.prototype.hasOwnProperty.call(countBraces, bc)) {
        const count = countBraces[bc];
        if (count == 1) {
          roll.forEach(el => {
            var _el$bcs2;
            if ((el === null || el === void 0 || (_el$bcs2 = el.bcs) === null || _el$bcs2 === void 0 ? void 0 : _el$bcs2.length) > 0) {
              if (el.bcs.slice(-1) == parseInt(bc)) {
                el.bcs.splice(-1, 1);
                applied = true;
              }
            }
          });
        }
      }
    }
    return applied;
  }
}