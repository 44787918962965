// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageSelectAndCropModal {
  position: absolute;
  height: 80%;
  width: 80%;
  background-color: #424242;
  border: 2px solid;
  box-shadow: #121212;
  padding: 16px 32px 24px;
  top: 10%;
  left: 10%;
}
.imageSelectAndCropModal .posBottomRight {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.imageSelectAndCropModal .posTopLeft {
  position: absolute;
  top: 8px;
  left: 8px;
}
.imageSelectAndCropModal .posTopRight {
  position: absolute;
  top: 8px;
  right: 8px;
}
.imageSelectAndCropModal .posTopLeft2 {
  position: absolute;
  top: 72px;
  left: 8px;
}
.imageSelectAndCropModal .posTopRight2 {
  position: absolute;
  top: 72px;
  right: 8px;
}
.imageSelectAndCropModal .centerSlider {
  width: calc(100% - 160px);
  top: 23px;
  left: 80px;
  position: "absolute";
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
